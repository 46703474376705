/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../common/useAxiosPrivate";
import uuid from "react-uuid";
import { CircleLoader } from "react-spinners";
import _ from "lodash";
import userStorage from "../../common/userStorage";
import { toast } from "react-toastify";
const { REACT_APP_API_BASE_URL } = process.env;
const BASE_URL = `${REACT_APP_API_BASE_URL}/api`;

const Spec = () => {
  const axiosPrivate = useAxiosPrivate();
  const [sections, setSections] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [updatedSections, setUpdatedSections] = useState<any>([]);
  const [specTemplate, setSpecTemplate] = useState<any>({});
  const [specTemplateModels, setSpecTemplateModels] = useState<any>([]);
  const [modelId, setModelId] = useState<any>("");

  useEffect(() => {
    getSpecTemplateModels();
  }, []);

  const getSpecTemplateModels = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("get_all_spec_models");
      setSpecTemplateModels(_.cloneDeep(response.data));

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const getSpecConfigurations = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("get_all_spec_sections");
      setSections(_.cloneDeep(response.data));
      setUpdatedSections(_.cloneDeep(response.data));

      resetSpecTemplate(response.data);

      setIsLoading(false);

      return response.data;
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      return null;
    }
  };

  const resetSpecTemplate = (template: any) => {
    let sections = prepareSections(template);

    setSpecTemplate({ id: "", model: "", sections: sections });
  };

  const prepareSections = (template: any) => {
    let sections = _.map(template, (sec) => {
      let properties = _.map(sec.properties, (p: any) => {
        return { id: p.id, value: "" };
      });
      return { id: sec.id, properties: properties };
    });
    return sections;
  };

  const clearAllData = () => {
    setSections([]);
    setUpdatedSections([]);
    setSpecTemplate({});
    setModelId("");
  };

  const getPropertyValue = (section: any, property: any, temp: any = null) => {
    let specTempSecs = !!temp ? temp.sections : specTemplate.sections;
    let value =
      _.find(
        _.find(specTempSecs, {
          id: section.id,
        })?.properties,
        { id: property.id }
      )?.value || "";
    return value;
  };

  const setPropertyName = (section: any, property: any, value: any) => {
    let sectionId = _.findIndex(specTemplate.sections, {
      id: section.id,
    });
    let propertyId = _.findIndex(specTemplate.sections[sectionId].properties, {
      id: property.id,
    });
    sections[sectionId].properties[propertyId].name = value;
    updatedSections[sectionId].properties[propertyId].name = value;
  };

  const setPropertyValue = (section: any, property: any, value: any) => {
    let sectionId = _.findIndex(specTemplate.sections, {
      id: section.id,
    });
    let propertyId = _.findIndex(specTemplate.sections[sectionId].properties, {
      id: property.id,
    });
    specTemplate.sections[sectionId].properties[propertyId].value = value;
    setSpecTemplate(_.cloneDeep(specTemplate));
  };

  const downloadPdf = async () => {
    let secs = _.map(specTemplate.sections, (sec, sIn) => {
      let section = _.find(sections, { id: sec.id });
      let properties = _.map(sec.properties, (prop, pInd) => {
        let property = _.find(section.properties, { id: prop.id });
        let value = prop.value;
        if (property.type === "dropdown") {
          let valueObj = _.find(property.values, { id: value });
          value = valueObj?.value;
        }
        return {
          name: property?.name,
          value: value,
        };
      });
      return {
        name: section?.name,
        column: section?.column,
        row: section?.row,
        properties: properties,
      };
    });

    let allTemp = {
      model: specTemplate?.model,
      sections: secs,
    };
    const userInfo = userStorage.getAuthInfo();
    fetch(`${BASE_URL}/settings/download_spec_pdf`, {
      method: "POST",
      body: JSON.stringify(allTemp),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.download = `${allTemp.model}-${new Date().toLocaleString()}.pdf`;
        a.href = href;
        a.click();
        a.href = "";
      })
      .catch((err) => console.error(err));
  };

  const syncSpc = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    try {
      setIsLoading(true);

      const response = await axiosPrivate.post("sync_spec_models");
      await axiosPrivate.post("sync_spec_segments");
      setIsLoading(false);
      toast.success("Successfully synced!");
    } catch (e) {
      setIsLoading(false);
      toast.error("Failed!");
    }
  };

  return (
    <>
      <div className={`mt-0 ${isLoading ? "disabled" : ""}`}>
        <div className="row m-t-10">
          <div className="col-md-4">
            <select
              value={modelId}
              className="form-select"
              aria-label="Default select example"
              onChange={async (e) => {
                setModelId(e.target.value);
                try {
                  if (!!e.target.value) {
                    if (_.isEmpty(sections)) {
                      let secs = await getSpecConfigurations();
                      specTemplate.sections = _.cloneDeep(secs);
                    } else {
                      specTemplate.sections = _.cloneDeep(sections);
                    }

                    let modelName =
                      e.target.options[e.target.selectedIndex].text;
                    specTemplate.model = modelName;
                    setSpecTemplate(_.cloneDeep(specTemplate));
                    setIsLoading(false);
                  } else {
                    clearAllData();
                  }
                } catch (error) {
                  setIsLoading(false);
                }
              }}
            >
              <option value="">Select Model</option>
              {specTemplateModels.map((model: any) => (
                <option key={uuid()} value={model.itemId}>
                  {model.description}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4 d-flex justify-content-start">
            <button className="btn btn-success" onClick={downloadPdf}>
              Download
            </button>
            <button className="btn btn-primary m-l-5" onClick={syncSpc}>
              Sync
            </button>
          </div>
        </div>
        <ul className="nav nav-tabs m-t-10" id="myTab" role="tablist">
          {sections.map((section: any, index: number) => (
            <li className="nav-item" role="presentation" key={uuid()}>
              <button
                onClick={() => {
                  setActiveTab(index);
                  setSections(_.cloneDeep(updatedSections));
                }}
                className={`nav-link ${activeTab == index ? "active" : ""}`}
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target={`#section-${index}`}
                type="button"
                role="tab"
                aria-controls={`section-${index}`}
                aria-selected="true"
              >
                <span>{section.name}</span>
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content p-t-10" id="myTabContent">
          {sections.map((section: any, index: number) => (
            <div
              key={uuid()}
              className={`tab-pane fade ${
                activeTab == index ? "show active" : ""
              }`}
              id={`section-${index}`}
              role="tabpanel"
              aria-labelledby={`section-${index}`}
            >
              <div className="property-container">
                {section.properties &&
                  section.properties.map((property: any, ind: number) => (
                    <div key={uuid()} className="m-b-5">
                      <div className="row m-b-5">
                        <div className="col-3">
                          {section.name === "DELETIONS" ? (
                            <input
                              type="text"
                              className={`form-control`}
                              defaultValue={property.name}
                              onChange={(e) => {
                                let value = e.target.value;
                                setPropertyName(section, property, value);
                              }}
                            />
                          ) : (
                            <strong>{property.name}</strong>
                          )}
                        </div>
                        <div className="col-9 d-flex">
                          {property.type == "dropdown" ? (
                            <select
                              className="form-select"
                              defaultValue={getPropertyValue(section, property)}
                              onChange={(e) => {
                                let value = e.target.value;
                                setPropertyValue(section, property, value);
                              }}
                            >
                              <option value="">Select {property.name}</option>
                              {property.values.map((val: any, vInd: number) => {
                                return (
                                  <option value={val.id} key={uuid()}>
                                    {val.value}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <input
                              type="text"
                              className={`form-control`}
                              defaultValue={getPropertyValue(section, property)}
                              onBlur={(e) => {
                                let value = e.target.value;
                                setPropertyValue(section, property, value);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <CircleLoader
        loading={isLoading}
        color="#36d7b7"
        cssOverride={{
          display: "block",
          margin: "0 auto",
          borderColor: "#36d7b7",
        }}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};

export default Spec;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import userStorage from "./userStorage";
import { useEffect, useState } from "react";
import "./Layout.css";

const Layout = () => {
  const allowedUsers = [
    "ashis-dc@crusadercaravans.com.au",
    "nirav@crusadercaravans.com.au",
    "rogy@crusadercaravans.com.au",
    "arifa-dc@crusadercaravans.com.au",
    "tawhid-dc@crusadercaravans.com.au",
    "jovin@crusadercaravans.com.au",
  ];
  const [authInfo, setAuthInfo] = useState<any>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const info = userStorage.getAuthInfo();
    setAuthInfo(info);
  }, []);
  const logout = () => {
    userStorage.revoveAllInfo();
    navigate("/");
  };
  return (
    <main className="App">
      <nav
        className="nav navbar navbar-expand-lg align-items-center justify-content-between"
        id="main-navbar"
      >
        <h2 className="d-flex align-items-center m-0">
          <NavLink to="/" className="navbar-brand">
            <img
              src={
                process.env.PUBLIC_URL + "/crusader_caravans_logo - 225x102.svg"
              }
              alt="Logo"
              className="img-fluid"
            />
          </NavLink>
        </h2>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {authInfo?.accessToken && (
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto align-items-center mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink to="/home" className="nav-link">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/spec" className="nav-link">
                  Spec
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink to="/spec-old" className="nav-link">
                  Spec Old
                </NavLink>
              </li> */}
              {allowedUsers?.includes(authInfo?.email) && (
                <>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Spec Configurations
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <NavLink to="/spec-sections" className="dropdown-item">
                          Spec Sections
                        </NavLink>
                      </li>
                      {/* <li>
                        <NavLink
                          to="/spec-sections-old"
                          className="dropdown-item"
                        >
                          Spec Sections Old
                        </NavLink>
                      </li> */}
                      <li>
                        <NavLink to="/spec-mappings" className="dropdown-item">
                          Spec Mappings
                        </NavLink>
                      </li>
                      {/* <li>
                        <NavLink to="/spec-template" className="dropdown-item">
                          Spec Template
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/spec-template-query"
                          className="dropdown-item"
                        >
                          Spec Template Query
                        </NavLink>
                      </li> */}
                      {/* <li>
                        <NavLink
                          to="/spec-configurations"
                          className="dropdown-item"
                        >
                          Spec Configurations
                        </NavLink>
                      </li> */}
                    </ul>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink to="/spec-configurations" className="nav-link">
                      Spec Configurations
                    </NavLink>
                  </li> */}
                  <li className="nav-item">
                    <NavLink to="/configurations" className="nav-link">
                      Configurations
                    </NavLink>
                  </li>
                </>
              )}
              <li className="nav-item">
                <p className="m-0 nav-link text-blue">{authInfo?.name}</p>
              </li>
              <li className="nav-item">
                <button className="btn table-btn" onClick={logout}>
                  Logout
                </button>
              </li>
            </ul>
            {/* <div className="d-flex">
                <div className="d-flex align-items-center">
                  <h6 style={{ margin: 0 }}>{authInfo?.name}</h6>
                </div>
                <button className="btn btn-sm" onClick={logout}>
                  Logout
                </button>
              </div> */}
          </div>
        )}
      </nav>
      <div className="main-body container-fluid">
        <div className="main-container">
          <Outlet />
        </div>
      </div>
    </main>
  );
};

export default Layout;

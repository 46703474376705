/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../common/useAxiosPrivate";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { CircleLoader } from "react-spinners";

const Configuration = () => {
  const axiosPrivate = useAxiosPrivate();
  const [settingFiles, setSettingFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [fileUploadEvent, setFileUploadEvent] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  useEffect(() => {
    getSettingFiles();
  }, []);
  const getSettingFiles = async () => {
    const response = await axiosPrivate.get("settings/get_setting_files");
    setSettingFiles(response.data);
  };
  const handleFileUpload = async (event: any) => {
    const selectedFile = event.target.files[0];
    if (!!selectedFile) {
      setSelectedFile(selectedFile);
      setFileUploadEvent(event);
    }
  };
  const handleChange = (event: any) => {
    setSelectedType(event.target.value);
  };
  const save = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      if (!!selectedFile && !!selectedType) {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("file_type", selectedType);
        const response = await axiosPrivate.post("settings/upload", formData);
        await getSettingFiles();
        setSelectedType("");
        setSelectedFile(null);
        toast.success("File uploaded successfully!");
        setIsLoading(false);
        fileUploadEvent.target.value = null;
      } else {
        toast.error("Select type and file!");
      }
    } catch (err: any) {
      setIsLoading(false);
      console.error(err);
      toast.error("Failed!");
    }
  };
  return (
    <>
      <div className={isLoading ? "disabled" : ""}>
        <div className="row m-b-10">
          <div className="col-md-12 d-flex">
            <div className="m-r-10">
              <input
                className="form-control"
                onChange={handleFileUpload}
                type="file"
                id="formFile"
              />
            </div>
            <div className="m-r-10">
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleChange}
                value={selectedType}
              >
                <option value="">Select Type</option>
                <option value="OrderProcessingSystemSettings">
                  Order Processing System Settings (CSV)
                </option>
                <option value="OptionDescriptionKeywords">
                  Option Description Keywords (JSON)
                </option>
              </select>
            </div>
            <div>
              <button className="btn btn-success" onClick={save}>
                Save
              </button>
            </div>
          </div>
        </div>
        <h5>Setting Files</h5>
        {settingFiles?.length && (
          <>
            {settingFiles.map((fileName: any) => (
              <div key={uuid()}>{fileName}</div>
            ))}
          </>
        )}
      </div>
      <CircleLoader
        loading={isLoading}
        color="#36d7b7"
        cssOverride={{
          display: "block",
          margin: "0 auto",
          borderColor: "#36d7b7",
        }}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};

export default Configuration;

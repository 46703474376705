/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../common/useAxiosPrivate";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { CircleLoader } from "react-spinners";
import "./spec-section.css";
import _ from "lodash";

const SpecMapper = () => {
  const axiosPrivate = useAxiosPrivate();
  const [sections, setSections] = useState<any>([]);
  const [specMappings, setSpecMappings] = useState<any>([]);
  const [invalidItemIdIndexes, setInvalidItemIdIndexes] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [updatedSections, setUpdatedSections] = useState<any>([]);
  const [updatedSpecMappings, setUpdatedSpecMappings] = useState<any>([]);
  const [onSave, setOnSave] = useState(false);

  useEffect(() => {
    setOnSave(false);
    setInvalidItemIdIndexes([]);
    getSpecConfigurations();
  }, []);
  const getSpecConfigurations = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("get_all_spec_sections");
      let allSections = _.cloneDeep(response.data);
      // allSections = _.map(allSections, (sec) => {
      //   let prop = _.map(sec.labels, (p) => {
      //     return {
      //       id: p.id,
      //       name: p.name,
      //       type: p.type,
      //       values: p.values,
      //       collapse: true,
      //     };
      //   });
      //   sec.labels = prop;
      //   return sec;
      // });
      setSections(_.cloneDeep(allSections));
      setUpdatedSections(_.cloneDeep(allSections));

      const mappingsResponse = await axiosPrivate.get("get_all_spec_mappings");

      const allMappings = _.map(allSections, (section) => {
        const mapping = _.find(mappingsResponse.data, {
          sectionId: section.id,
        });
        if (_.isEmpty(mapping)) {
          return {
            sectionId: section.id,
            mappings: [],
          };
        }
        return mapping;
      });
      setSpecMappings(_.cloneDeep(allMappings));
      setUpdatedSpecMappings(_.cloneDeep(allMappings));

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };
  const getSectionById = (sectionId: string) => {
    return _.find(sections, { id: sectionId });
  };
  const getLabelsBySectionId = (sectionId: string) => {
    return _.find(sections, { id: sectionId }).labels;
  };
  const syncSpc = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setOnSave(true);
    try {
      setIsLoading(true);

      const response = await axiosPrivate.post("sync_spec_models");
      await axiosPrivate.post("sync_spec_segments");
      setIsLoading(false);
      toast.success("Successfully synced!");
      setOnSave(false);
    } catch (e) {
      setIsLoading(false);
      setOnSave(false);
      toast.error("Failed!");
    }
  };
  const saveSpcConfiguration = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setOnSave(true);
    setInvalidItemIdIndexes([]);
    // let indexes: any = [];
    let invalidIndex = -1;
    for (var i = 0; i < updatedSpecMappings.length; i++) {
      // if (
      //   !updatedSpecMappings[i].labelId ||
      //   !updatedSpecMappings[i].itemId ||
      //   !updatedSpecMappings[i].cotsDescription ||
      //   !updatedSpecMappings[i].specText
      // ) {
      //   invalidIndex = i;
      //   break;
      // }
      // if (updatedSections[i].mappings.length == 0) {
      //   invalidIndex = i;
      // }
      for (var j = 0; j < updatedSpecMappings[i].mappings.length; j++) {
        if (
          // updatedSections[i].name !== "DELETIONS" &&
          !updatedSpecMappings[i].mappings[j].labelId ||
          !updatedSpecMappings[i].mappings[j].itemId ||
          !updatedSpecMappings[i].mappings[j].cotsDescription ||
          !updatedSpecMappings[i].mappings[j].specText
        ) {
          invalidIndex = i;
          break;
        } else if (
          _.filter(updatedSpecMappings[i].mappings, {
            itemId: updatedSpecMappings[i].mappings[j].itemId,
          }).length > 1
        ) {
          for (var k = 0; k < updatedSpecMappings[i].mappings.length; k++) {
            if (
              updatedSpecMappings[i].mappings[j].itemId ==
              updatedSpecMappings[i].mappings[k].itemId
            ) {
              invalidItemIdIndexes.push(k);
            }
          }
          setInvalidItemIdIndexes(_.cloneDeep(invalidItemIdIndexes));
          invalidIndex = i;
          break;
        }
      }
      if (invalidIndex > -1) {
        break;
      }
    }
    if (invalidIndex > -1) {
      setActiveTab(invalidIndex);
      setSpecMappings(_.cloneDeep(updatedSpecMappings));
      toast.error("Validation failed!");
    }
    if (invalidIndex == -1) {
      try {
        let allSpecMappings = _.cloneDeep(updatedSpecMappings);
        setSpecMappings(_.cloneDeep(allSpecMappings));
        setUpdatedSpecMappings(_.cloneDeep(allSpecMappings));
        setIsLoading(true);

        const response = await axiosPrivate.post(
          "save_spec_mappings",
          updatedSpecMappings
        );
        setIsLoading(false);
        toast.success("Successfully saved!");
        setOnSave(false);
        // await getSpecConfigurations();
        // setActiveTab(0);
      } catch (e) {
        setIsLoading(false);
        setOnSave(false);
        toast.error("Failed!");
      }
    }
    invalidIndex = -1;
  };

  return (
    <>
      <div className={`mt-0 ${isLoading ? "disabled" : ""}`}>
        <button className="btn btn-success" onClick={saveSpcConfiguration}>
          Save
        </button>
        <button className="btn btn-primary m-l-5" onClick={syncSpc}>
          Sync
        </button>
        <ul className="nav nav-tabs m-t-10" id="myTab" role="tablist">
          {specMappings.map((specMapping: any, index: number) => (
            <li className="nav-item" role="presentation" key={uuid()}>
              <button
                onClick={() => {
                  setActiveTab(index);
                  setSpecMappings(_.cloneDeep(updatedSpecMappings));
                }}
                className={`nav-link ${activeTab == index ? "active" : ""}`}
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target={`#section-${index}`}
                type="button"
                role="tab"
                aria-controls={`section-${index}`}
                aria-selected="true"
              >
                <span>{getSectionById(specMapping.sectionId).name}</span>
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content" id="myTabContent">
          {specMappings.map((specMapping: any, index: number) => (
            <div
              key={uuid()}
              className={`tab-pane fade ${
                activeTab == index ? "show active" : ""
              }`}
              id={`section-${index}`}
              role="tabpanel"
              aria-labelledby={`section-${index}`}
            >
              <div className="row m-t-5">
                <div className="col-3">Label</div>
                <div className="col-2">Item Id</div>
                <div className="col-3">COTs Description</div>
                <div className="col-4">Spec Text</div>
              </div>
              <div className="property-container">
                {specMapping.mappings &&
                  specMapping.mappings.map((mapping: any, ind: number) => (
                    <div key={uuid()} className="m-b-5">
                      <div className="row m-b-5">
                        <div className="col-3">
                          <select
                            className={`form-control ${
                              onSave && !mapping.labelId ? "error-field" : ""
                            }`}
                            defaultValue={mapping.labelId}
                            onChange={(event) => {
                              updatedSpecMappings[index].mappings[ind].labelId =
                                event.target.value;
                              setUpdatedSpecMappings(updatedSpecMappings);
                            }}
                          >
                            <option key={uuid()} value="">
                              Select Label
                            </option>
                            {getLabelsBySectionId(specMapping.sectionId).map(
                              (option: any) => (
                                <option key={uuid()} value={option.id}>
                                  {option.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        <div className="col-2">
                          <input
                            type="text"
                            className={`form-control ${
                              onSave &&
                              (!mapping.itemId ||
                                invalidItemIdIndexes.includes(ind))
                                ? "error-field"
                                : ""
                            }`}
                            placeholder="Item Id"
                            defaultValue={mapping.itemId}
                            onChange={(e) => {
                              let val = e.target.value;
                              updatedSpecMappings[index].mappings[ind].itemId =
                                val;
                              setUpdatedSpecMappings(updatedSpecMappings);
                            }}
                            onBlur={async (e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              let val = e.target.value;
                              try {
                                setIsLoading(true);
                                updatedSpecMappings[index].mappings[
                                  ind
                                ].itemId = val;
                                setUpdatedSpecMappings(updatedSpecMappings);
                                setSpecMappings(updatedSpecMappings);
                                const response = await axiosPrivate.get(
                                  `get_spec_segment_by_item_id/${val}`
                                );
                                updatedSpecMappings[index].mappings[
                                  ind
                                ].cotsDescription = response.data.description;
                                setUpdatedSpecMappings(updatedSpecMappings);
                                setSpecMappings(updatedSpecMappings);
                                setIsLoading(false);
                              } catch (e) {
                                setIsLoading(false);
                                toast.error("Not Found!");
                              }
                            }}
                          />
                        </div>
                        <div className="col-3">
                          <input
                            type="text"
                            className={`form-control ${
                              onSave && !mapping.cotsDescription
                                ? "error-field"
                                : ""
                            }`}
                            placeholder="COTs Description"
                            defaultValue={mapping.cotsDescription}
                            disabled
                            onChange={(e) => {
                              let val = e.target.value;
                              updatedSpecMappings[index].mappings[
                                ind
                              ].cotsDescription = val;
                              setUpdatedSpecMappings(updatedSpecMappings);
                            }}
                          />
                        </div>
                        <div className="col-4 d-flex">
                          <input
                            type="text"
                            className={`form-control ${
                              onSave && !mapping.specText ? "error-field" : ""
                            }`}
                            placeholder="Spec Text"
                            defaultValue={mapping.specText}
                            onChange={(e) => {
                              let val = e.target.value;
                              updatedSpecMappings[index].mappings[
                                ind
                              ].specText = val;
                              setUpdatedSpecMappings(updatedSpecMappings);
                            }}
                          />
                          <button
                            className="btn btn-sm"
                            onClick={() => {
                              let allMappings =
                                _.cloneDeep(updatedSpecMappings);
                              allMappings[index].mappings.splice(ind, 1);
                              // setSections(_.cloneDeep(allSections));
                              // setUpdatedSections(_.cloneDeep(allSections));

                              setSpecMappings(_.cloneDeep(allMappings));
                              setUpdatedSpecMappings(_.cloneDeep(allMappings));
                            }}
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="d-flex w-100 justify-content-end p-b-10">
                <button
                  className="btn btn-sm btn-success"
                  onClick={(event) => {
                    let allMappings = _.cloneDeep(updatedSpecMappings);
                    allMappings[index].mappings.push({
                      sectionId: specMapping.sectionId,
                      labelId: "",
                      itemId: "",
                      cotsDescription: "",
                      specText: "",
                    });
                    setSpecMappings(_.cloneDeep(allMappings));
                    setUpdatedSpecMappings(_.cloneDeep(allMappings));
                  }}
                >
                  Add New Mapping
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <CircleLoader
        loading={isLoading}
        color="#36d7b7"
        cssOverride={{
          display: "block",
          margin: "0 auto",
          borderColor: "#36d7b7",
        }}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};

export default SpecMapper;
